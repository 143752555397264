import React from "react";

import { Col, Row } from "reactstrap";
import { Power,Menu, Home } from "react-feather";
import SidebarLeft from "./sidebarLeft";
import utils from "../../utils";

import PosManagerment from "../Admin/content/posManagerment";
import WinPrize from "../Admin/content/winPrize";
import ClerkHistory from "../Admin/content/clerkHistory";
import AccountantHistory from "../Admin/content/accountantHistory";
import AdminHistory from "../Admin/content/adminHistory";
import HistoryPersonal from "./historyPersonal"
import TicketStatistical from "../Admin/content/ticketStatistical";
import UserStatistical from "../Admin/content/userStatistical";
import UserStatisticalV2 from "../Admin/content/UserStaticalV2";
import CustomerTransactions from "../Admin/content/customerTransactions";
import StaffStatistical from "../Admin/content/staffStatistical";
import RevenueStatistics from "../Admin/content/revenueStatistics";
import MoneyHoldingStatistics from "../Admin/content/moneyHoldingStatistics";
import MoneyHoldingStatisticsOnDay from "../Admin/content/moneyHoldingStatisticsOnDay";
import RejectTicket from "../Admin/content/rejectTicket";
import StaffMonitor from '../Admin/content/staffMonitor'
import Budget from "./budget"
import logo from "../../assets/img/photos/logo.png";

const header = require('../Admin/header.json')

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            optionTab: "budget",
            click: "budget",
            showSidebar: false
        };
        // check auth
        let uesrinfo = utils.getUserinfo();
        if (utils.isEmpty(uesrinfo) || uesrinfo.role !== "accountancy") window.location.replace("/");

    }

    setOptionTab(tab) {
        this.setState({ optionTab: tab, click: tab, showSidebar: false });
    }

    toggle(Component){
        this.setState({
            [Component] : !this.state[Component]
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className='group-accountant'>
                    <Row className='p-0 m-0 accountant'>
                        <Col xs="12" lg={ 2} className={`custom-sidebar-fixed p-0 m-0 ${this.state.showSidebar?"d-block":"hidden-md-down"} border`}>
                            <a className='sidebar-brand ml-5 hidden-md-down' href=''>
                                <img width="100px" style={{maxHeight:"120"}} src={logo} alt='logo' />
                            </a>
                            <button className="float-left bg-white border-0 hidden-lg-up" onClick={this.toggle.bind(this,"showSidebar")} > <Menu/> </button>
                            <SidebarLeft className='border mt-5' setOptionTab={this.setOptionTab.bind(this)} click={this.state.click} />
                        </Col>
                        <Col xs="12" lg={10} className={`${this.state.showSidebar?"d-none":""} group-content`}>
                            <div className='main-content'>
                                <div className='text-center pt-3 content-title-bold'>
                                    <button className="float-left bg-white border-0 hidden-lg-up" onClick={this.toggle.bind(this,"showSidebar")} > <Menu/> </button>
                                    {header[this.state.optionTab]}
                                    <button
                                        className='text-danger bg-white border-0 float-right mr-1 mt-1'
                                        onClick={() => {
                                            utils.logOut();
                                        }}>
                                        {" "}
                                        <Power className='mr-2' />
                                        {utils.getUserinfo().full_name}{" "}
                                    </button>
                                    <button className="text-primary bg-white border-0 float-right mr-1 mt-1 " onClick={()=>{window.location.replace("/accountancy")}}>  <Home className="mr-2"/></button>
                                </div>
                                <hr />
                                {this.state.optionTab === "winPrize" ? (
                                    <WinPrize />
                                ) : this.state.optionTab === "clerkHistory" ? (
                                    <ClerkHistory />
                                ) : this.state.optionTab === "accountantHistory" ? (
                                    <AccountantHistory />
                                ) : this.state.optionTab === "adminHistory" ? (
                                    <AdminHistory />
                                ) : this.state.optionTab === "historyPersonal" ? (
                                    <HistoryPersonal />
                                ) : this.state.optionTab === "ticketStatistical" ? (
                                <TicketStatistical />
                                ) : this.state.optionTab === "userStatistical" ? (
                                    <UserStatistical />
                                ) :
                                    this.state.optionTab === "userStatisticalV2" ? (
                                    <UserStatisticalV2 />)
                                    :
                                 this.state.optionTab === "customerTransactions" ? (
                                    <CustomerTransactions />
                                ) : this.state.optionTab === "staffStatistical" ? (
                                    <StaffStatistical />
                                ) : this.state.optionTab === "revenueStatistics" ? (
                                    <RevenueStatistics />
                                ) : this.state.optionTab === "moneyHoldingStatistics" ? (
                                    <MoneyHoldingStatistics />
                                ) : this.state.optionTab === "moneyHoldingStatisticsOnDay" ? (
                                    <MoneyHoldingStatisticsOnDay />
                                ) : this.state.optionTab === "rejectTicket" ? (
                                    <RejectTicket />
                                ) : this.state.optionTab === "budget" ? (
                                    <Budget />
                                ) : this.state.optionTab === "posManagerment" ? (
                                    <PosManagerment />
                                ) : this.state.optionTab === "staffmanagement" ? (
                                    <StaffMonitor />
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
