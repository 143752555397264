import React from "react";
import {
    Col, Row, Table,
    Button,
    InputGroup, InputGroupAddon, Input,CustomInput,
    Modal,ModalBody,ModalHeader,ModalFooter,
} from "reactstrap";
import {  Search} from "react-feather";
import "react-tabs/style/react-tabs.css";
import ModalCamera from "../../components/ModalCamera"
import utils from "../../utils";
import Notification from "../../components/Notification";
import ButtonTimeout from "../../components/ButtonTimeout";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const apiAccountant = require("./api/apiAccountant");
const attachment = require("../apiGeneral/attachment");
const getCustommerFromPhone = require("../apiGeneral/getCustommerFromPhone");
const getHistory = require("./api/apiHistory");
const apiConfig = require("../apiGeneral/apiConfig");
const actionRechargeOnDay = require("../../config/config").rechargeOnDay;

const ModalImage = (props) => {
    return (
        <React.Fragment>
            <Modal isOpen={props.isOpen} centered className="modal-camera" size="lg">
                <ModalHeader toggle={props.toggle}>
                    {props.label}
                </ModalHeader>
                <ModalBody className="text-center">
                    <img src={props.src}  height={480} style={{maxWidth:"100%"}}/>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
};

class ReCharge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            money: 0,
            phone_number: "",

            dataCustommer:null,
            dataBanking:this.props.dataBanking,
            isRequest:false,
            note:null,
            reason: null,

            srcProof: null,
            idBanking: null,

            imgSrc: null,
            imgLink: null,

            historyCustomer: [],

            show_imageProof: false,
            show_reason:false,
            amountMax : null,

            historyPage : 1,
            actionFindter: null
        }
    }

    handleChange(event) {
        if (event.target.name === "money") {
            let amount = event.target.value.replace(/[^\d]/g, "");
            if (utils.isEmpty(amount))
                amount = 0;
            try {
                amount = parseInt(amount);
                if (Number.isNaN(amount))
                    return;
                this.setState({money: amount});
                return;
            } catch (e) {
                return;
            }
        }
        this.setState({
            [event.target.name]: event.target.value.replace(/[^\d]/g, ""),
            isRequest: false
        })
    }

    inputOnChange(event){
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSearch() {
        if(this.state.phone_number.length < 9){
            Notification("warning","Không tồn tại số điện thoại trong hệ thống");
        }
        else{
            if(!utils.isEmpty(this.state.phone_number)){
                getCustommerFromPhone.getData(this.state.phone_number,(err,result)=>  {
                    if(err){
                        Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại định dạng các trường đầu vào");
                    } else {
                        if(utils.isEmpty(result)){
                            Notification("warning","Không tồn tại số điện thoại trong hệ thống");
                        }
                        let state = Object.assign({}, this.state);
                        state.dataCustommer = result;
                        state.historyCustomer=[];
                        state.money= 0;
                        state.imgSrc= null;
                        state.imgLink= null;
                        this.setState(state);
                    }
                });
            }
        }
    }

    handleReCharge() {
        utils.confirmInput(`Xác nhận nạp <span class="money-title">${this.state.money.getMoneyFormat()} đồng</span> vào tài khoản <span class="content-title-bold">${this.state.phone_number}</span>`, (res) => {
            if(res!==false){
                if(this.state.isRequest){
                    apiAccountant.addMoney({phoneNumber:this.state.dataCustommer.phoneNumber,amount:(this.state.money/1000).toString(),proof:this.state.imgLink,note:"",reason: res},(err,result)=>  {
                        console.log(err);
                        if(err){
                            if(err.data==="Too many request created from this IP, please try again!"){
                                Notification("warning", "Lỗi", "Không được nhấn nút liên tiếp")
                            } else{
                                switch (err.data._error_message) {
                                    case "Amount must be a positive number":
                                        Notification("error", "Lỗi", "Số tiền phải là một số dương")
                                        break;
                                    case "Amount too much":
                                        Notification("error", "Lỗi", "Số tiền quá giới hạn cho phép trong một lần nạp")
                                        break;
                                    case "Out of money per day":
                                        Notification("error", "Lỗi", "Nhân viên nạp quá giới hạn cho phép trong một ngày")
                                        break;
                                    case "Phone number doesn't exist or not activated":
                                        Notification("error", "Lỗi", "Số điện thoại không tồn tại hoặc chưa kích hoạt")
                                        break;
                                    default:
                                        Notification("error", "Lỗi", "Vui lòng thử lại sau!")
                                        break;
                                }
                            }
                            
                        } else {
                            apiAccountant.update(this.state.idBanking,{status:"1"},(err)=>  {
                                if(err){
                                    Notification("error", "Lỗi", "Đã nạp tiền Không thể cập nhật yêu cầu nạp tiền");
                                }
                                else{
                                    Notification("success" ,"Nạp tiền thành công.");
                                }
                            });
                            let state = Object.assign({}, this.state);
                            state.money=0;
                            state.phone_number="";
                            state.imgLink=null;
                            state.imgSrc=null;
                            state.dataCustommer=null;
                            state.srcProof=null;
                            state.note=null;
                            state.srcProof= null;
                            state.idBanking= null;
                            state.isRequest = false;
                            state.historyCustomer=[];
                            this.setState(state);
                            this.props.toggle("clearDataHandling");
                        }
                    });
                }else{
                    apiAccountant.addMoney({phoneNumber:this.state.phone_number,amount:(this.state.money/1000).toString(),proof:this.state.imgLink,note:""},(err,result)=>  {
                        console.log(err);
                        if(err){
                            if(err.data==="Too many request created from this IP, please try again!"){
                                Notification("warning", "Lỗi", "Không được nhấn nút liên tiếp")
                            } else{
                                switch (err.data._error_message) {
                                    case "Amount must be a positive number":
                                        Notification("error", "Lỗi", "Số tiền phải là một số dương")
                                        break;
                                    case "Amount too much":
                                        Notification("error", "Lỗi", "Số tiền quá giới hạn cho phép trong một lần nạp")
                                        break;
                                    case "Out of money per day":
                                        Notification("error", "Lỗi", "Nhân viên nạp quá giới hạn cho phép trong một ngày")
                                        break;
                                    case "Phone number doesn't exist or not activated":
                                        Notification("error", "Lỗi", "Số điện thoại không tồn tại hoặc chưa kích hoạt")
                                        break;
                                    default:
                                        Notification("error", "Lỗi", "Vui lòng nhập đủ các trường")
                                        break;
                                }
                            }
                            
                        } else {
                            Notification("success" ,"Nạp tiền thành công");
                            let state = Object.assign({}, this.state);
                            state.money=0;
                            state.phone_number="";
                            state.imgSrc=null;
                            state.imgLink=null;
                            state.dataCustommer=null;
                            state.srcProof=null;
                            state.note=null;
                            state.srcProof= null;
                            state.idBanking= null;
                            state.isRequest = false;
                            state.historyCustomer=[];
                            this.setState(state);
                            this.props.toggle("clearDataHandling");
                        }
                    });
                }
            }
        })
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        })
    }

    handleShooting(id,imgSrc,imgLink) {
        this.setState({imgSrc:imgSrc,imgLink: imgLink});
    }

    handleRemovePhoto(id) {
        this.setState({imgSrc: null,imgLink: null});
    }


    checkEmptyField(){
        if(utils.isEmpty(this.state.dataCustommer)){
            Notification("error","Chưa đủ thông tin","Chưa có thông tin khách hàng");
        }
        else if(utils.isEmpty(this.state.money) || this.state.money===0){
            Notification("error","Chưa đủ thông tin","Số tiền muốn nạp không được để trống");
        }
        // else if(this.state.money%1000 !== 0){
        //     Notification("error","Số tiền không hợp lệ","Số phải chia hết cho 1000 đồng");
        // }
        else if(utils.isEmpty(this.state.imgLink)){
            this.setState({imgLink: 'nap.jpg'});
            this.handleReCharge();
           // Notification("error","Chưa đủ thông tin","Chưa chụp hóa đơn");
        }
        else{
            this.handleReCharge();
        }
    }

    handReject(){
        if(utils.isEmpty(this.state.idBanking)){
            Notification("error","Không thể hủy","Không có thông tin yêu cầu");
        }
        else{
            utils.confirm("Xác nhận hủy yêu cầu nạp tiền", (check) => {
                if (check) {
                    this.apiUpdateCustomerRequestStatus(this.state.idBanking,"2");
                    let state = Object.assign({}, this.state);
                    state.money=0;
                    state.phone_number="";
                    state.imgSrc=null;
                    state.imgLink=null;
                    state.dataCustommer=null;
                    state.srcProof=null;
                    state.isRequest = false;
                    state.note = null;
                    state.historyCustomer=[];
                    this.setState(state);
                    this.props.toggle("clearDataHandling");
                }
            });
        }
    }

    handTakeAway(){
        if(utils.isEmpty(this.state.idBanking)){
            Notification("error","Không thể thực hiện","Không có thông tin yêu cầu");
        }
        else{
            apiAccountant.update(this.state.idBanking,{readLater:true},(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra", "Vui lòng hủy bỏ yêu cầu");
                }
                else{
                    Notification("success" ,"Đã đưa yêu cầu vào danh sách xử lý sau");
                }
            });
            let state = Object.assign({}, this.state);
            state.money=0;
            state.phone_number="";
            state.imgSrc=null;
            state.imgLink=null;
            state.dataCustommer=null;
            state.srcProof=null;
            state.isRequest = false;
            state.note = null;
            state.historyCustomer=[];
            this.setState(state);
            this.props.toggle("takeAway");
        }
    }

    onImageFileChange(event){
        if (event.target.files && event.target.files[0]) {
            let state = Object.assign({}, this.state);
            state.imgLink = attachment.getLinkAttachment(event.target.files[0],"wallet");
            let reader = new FileReader();
            reader.onload = (e) => {
                state.imgSrc = e.target.result;
            };
            attachment.apiAttachment(event.target.files[0],"wallet",(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    state.imgLink = result.pathUrl;
                    this.setState(state);
                }
            });
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    apiUpdateCustomerRequestStatus(id,status){
        apiAccountant.update(id,{status:status},(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                if(status==="1") Notification("success", "Nạp tiền thành công",);
                else if(status==="2") Notification("success", "Hủy yêu cầu thành công",);
            }
        });
    }

    getHistoryCustomer(dataCustommer,page,actionFindter){
        if(utils.isEmpty(dataCustommer)){
            Notification("error", "Không thể mở lịch sử","Không có thông tin khách hàng");
        }
        else{
            getHistory.getHistory(dataCustommer.phoneNumber,page,actionFindter,(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    this.setState({historyCustomer: result})
                }
            })
        }
    }

    handClickHistoryCustomer(){
        if(utils.isEmpty(this.state.historyCustomer)){
            this.getHistoryCustomer(this.state.dataCustommer,this.state.historyPage,this.state.actionFindter);
        } 
        else{
            this.setState({historyCustomer:[]})
        }
    }

    handPrePageHistory(){
        if(this.state.historyPage>1){
            this.getHistoryCustomer(this.state.dataCustommer,this.state.historyPage-1,this.state.actionFindter);
            this.setState({historyPage: this.state.historyPage-1})
        }
    }

    handNextPageHistory(){
        this.getHistoryCustomer(this.state.dataCustommer,this.state.historyPage+1,this.state.actionFindter);
        this.setState({historyPage: this.state.historyPage+1})
    }

    handChangeOptionHistory(event){
        if (event.target.value === "Nạp tiền"){
            this.getHistoryCustomer(this.state.dataCustommer,1,"fluctuationTypeId[]=3&fluctuationTypeId[]=4&fluctuationTypeId[]=5");
            this.setState({historyPage: 1, actionFindter:"fluctuationTypeId[]=3&fluctuationTypeId[]=4&fluctuationTypeId[]=5"})
        }else if (event.target.value === "Rút tiền"){
            this.getHistoryCustomer(this.state.dataCustommer,1,"fluctuationTypeId[]=2&fluctuationTypeId[]=9");
            this.setState({historyPage: 1, actionFindter:"fluctuationTypeId[]=2&fluctuationTypeId[]=9"})
        }else if (event.target.value === "Mua vé"){
            this.getHistoryCustomer(this.state.dataCustommer,1,"fluctuationTypeId[]=1");
            this.setState({historyPage: 1, actionFindter:"fluctuationTypeId[]=1"})
        }else if (event.target.value === "Tất cả hoạt động"){
            this.getHistoryCustomer(this.state.dataCustommer,1);
            this.setState({historyPage: 1, actionFindter:null})
        }
    }

    uploadReason(){
        if(utils.isEmpty(this.state.idBanking)){
            Notification("error","Không thể hủy","Không có thông tin yêu cầu");
        }
        else if(utils.isEmpty(this.state.reason)){
            Notification("error","Lỗi","Lý do hủy yêu cầu không được để trống");
        }
        else{
            apiAccountant.update(this.state.idBanking,{status:"2",reason: this.state.reason},(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    Notification("success", "Hủy yêu cầu thành công",);
                    let state = Object.assign({}, this.state);
                    state.idBanking=null;
                    state.money=0;
                    state.phone_number="";
                    state.imgSrc=null;
                    state.imgLink=null;
                    state.dataCustommer=null;
                    state.srcProof=null;
                    state.isRequest = false;
                    state.note = null;
                    state.historyCustomer=[];
                    state.reason=null;
                    state.show_reason=false;
                    this.setState(state);
                    this.props.toggle("clearDataHandling");
                }
            });
        }
    }

    componentDidMount(){
        apiConfig.getConfig((err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({amountMax: result});
            }
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(!utils.isEmpty(nextProps.dataBanking)){
            getCustommerFromPhone.getData(nextProps.dataBanking.customer.phoneNumber,(err,result)=>  {
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    let state = Object.assign({}, this.state);
                    state.phone_number=result.phoneNumber;
                    state.dataCustommer = result;
                    state.money = nextProps.dataBanking.amount*1000;
                    state.note = nextProps.dataBanking.note;
                    state.srcProof = nextProps.dataBanking.proof;
                    state.idBanking = nextProps.dataBanking.id;
                    state.isRequest = true;
                    this.setState(state);
                }
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <ModalImage id={"imgProof"}
                            label = {"Ảnh chuyển tiền ngân hàng"}
                            isOpen={this.state.show_imageProof}
                            src={utils.getURLImage(this.state.srcProof)}
                            toggle={this.toggle.bind(this, "show_imageProof")}
                />
                <Modal isOpen={this.state.show_reason}>
                    <ModalHeader>Lý do hủy yêu cầu</ModalHeader>
                    <ModalBody>
                        <CustomInput
                            style={{fontSize:"20px"}}
                            size = "10"
                            fontSize = "20px"
                            type="select"
                            id="reason"
                            name="reason"
                            onChange = { this.inputOnChange.bind(this) }
                            multiple
                        >
                            {
                                this.props.listReason===null
                                ?(
                                    null
                                ):(
                                    this.props.listReason.map((value,index)=>{
                                        return(
                                            <option key={index} className="border-bottom"> {value.reason}</option>
                                        )
                                    })
                                )
                            }
                        </CustomInput>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle.bind(this,"show_reason",null,null)}>
                        Hủy bỏ
                        </Button>
                        <Button color="success" onClick={this.uploadReason.bind(this)}>
                        Xác nhận
                        </Button>
                    </ModalFooter>
                </Modal>
                <Col className={this.props.className}>
                    <Row className="_tittle-blue">
                        <Search/> TÌM KIẾM THÔNG TIN KHÁCH HÀNG NẠP TIỀN
                    </Row>
                    <Row className= "mt-2 ">
                        <Col sx="12" md="4">
                            <InputGroup style={{height:"35px"}}>
                                <InputGroupAddon addonType={"append"}>Số ĐT</InputGroupAddon>
                                <Input type="text"
                                    className="h-100"
                                    name="phone_number"
                                    onChange={this.handleChange.bind(this)}
                                    value={this.state.phone_number}
                                    maxLength="10"
                                    readOnly= {this.state.isRequest}
                                    onKeyUp={(event) => {
                                        if(isNaN(event.target.value)) {event.target.value = event.target.value.replace(/[^\d]/g, "");}
                                        if (event.key === "Enter") {
                                            this.handleSearch();
                                        }
                                    }}
                                />
                                <InputGroupAddon addonType={"prepend"}>
                                    <Button onClick={this.handleSearch.bind(this)}
                                    >
                                        Tìm kiếm
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Row>
                                <Table bordered>
                                    <thead>
                                    <tr>
                                        <th style={{width:"20%"}}>Họ tên</th>
                                        <th style={{width:"20%"}}>Số điện thoại</th>
                                        <th style={{width:"20%"}}>Số CMND</th>
                                        <th style={{width:"20%"}}>Số dư tài khoản</th>
                                        <th style={{width:"20%"}}>Số dư khả dụng</th>
                                        <th style={{width:"20%"}}>Trúng thưởng</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{this.state.dataCustommer?this.state.dataCustommer.fullName:"Trống"}</td>
                                        <td>{this.state.dataCustommer?this.state.dataCustommer.phoneNumber:"Trống"}</td>
                                        <td>{this.state.dataCustommer?this.state.dataCustommer.idCardNumber:"Trống"}</td>
                                        <td>{this.state.dataCustommer?utils.convertMoneyFomat(this.state.dataCustommer.wallet.balance):"0"}</td>
                                        <td>{this.state.dataCustommer?utils.convertMoneyFomat(this.state.dataCustommer.wallet.availableBalance):"0"}</td>
                                        <td>{this.state.dataCustommer?utils.convertMoneyFomat(this.state.dataCustommer.wallet.winingBalance):"0"}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Row>
                            <Row className="mt-1">
                                    <span className="mb-1">Nhập số tiền cần nạp</span>
                                    <InputGroup >
                                        <InputGroupAddon addonType={"append"}  style={{height:"35px"}}>Số tiền</InputGroupAddon>
                                        <Input
                                            className="h-100"
                                            type="text"
                                            name="money"
                                            onChange={this.handleChange.bind(this)}
                                            value={this.state.money.getMoneyFormat()}
                                            maxLength="15"
                                        />
                                    </InputGroup>
                                    <Col xs="12" md="6" xl="4" className=" text-center p-2">
                                        <ModalCamera
                                            label="Ảnh hóa đơn"
                                            id="imageBill"
                                            imgSrc = {this.state.imgSrc}
                                            folder = "wallet"
                                            actionWhenReceiveData = {this.handleShooting.bind(this)}
                                            actionWhenDeleteImage = {this.handleRemovePhoto.bind(this)}
                                            buttonSelectFile = {true}
                                        />
                                    </Col>
                                    <Col xs="12" md="6" xl="4" className=" p-2 text-center">
                                        <div className="content-title-bold ">Ảnh chuyển tiền ngân hàng</div>
                                        {
                                            utils.isEmpty(this.state.srcProof)
                                            ?   <img
                                                    className="ticket-photo w-50 "
                                                />
                                            :   <img 
                                                    src={utils.getURLImage(this.state.srcProof)}
                                                    className="ticket-photo w-50 "
                                                    onClick={this.toggle.bind(this,"show_imageProof")}
                                                />
                                        }
                                    </Col>  
                                    <Col xs="12" md="12" xl="4" className=" p-2 text-center ">
                                        <div className="content-title-bold ">Ghi chú của khách hàng</div>
                                        <center>
                                            <Input
                                                value={utils.isEmpty(this.state.note)?"Không có ghi chú":this.state.note}
                                                className=" note d-flex justify-content-center"
                                                type="textarea"
                                                name="textarea"
                                                readOnly= {true}
                                                placeholder="Không có ghi chú"
                                                rows="9"
                                            />
                                        </center>
                                    </Col>
                                
                            </Row>
                            
                            <Row className="mt-4 text-center justify-content-center">
                                <ButtonTimeout className="mr-2 p-3  w-20 btn-success"
                                        onClick={this.checkEmptyField.bind(this)}
                                >
                                    Nạp tiền
                                </ButtonTimeout>
                                <Button className="mr-2 p-3  w-20"
                                        onClick={this.handClickHistoryCustomer.bind(this)}
                                >
                                    {utils.isEmpty(this.state.historyCustomer)?"Lịch sử khách hàng":"Đóng lịch sử khách hàng"}
                                </Button>
                                <Button className="mr-2 p-3 w-20 btn-warning" 
                                        onClick={this.handTakeAway.bind(this)}
                                >
                                    Xử lý sau
                                </Button>
                                <Button className="mr-2 p-3 w-20 btn-danger" 
                                        onClick={this.state.idBanking ? this.toggle.bind(this,"show_reason",null,null): null}
                                >
                                    Hủy yêu cầu
                                </Button>
                                
                            </Row>
                            <center>
                            <Row className={utils.isEmpty(this.state.historyCustomer) ? "d-none":"d-block mt-4"}>
                                <ReactHTMLTableToExcel
                                    id="table-xls-button"
                                    className="download-table-xls-button float-right d-inline mx-2"
                                    table="table-customer-History"
                                    filename={`LỊCH SỬ TỪ ${utils.convertDate(this.state.fromDate)} ĐẾN ${utils.convertDate(this.state.toDate)} SĐT ${this.state.dataCustommer?this.state.dataCustommer.phoneNumber:"Trống"}`}
                                    sheet={`sheet1`}
                                    buttonText="Tải xuống"
                                />
                                <Input type="select" name="option_history" className="w-20 float-right mb-2" onChange={this.handChangeOptionHistory.bind(this)}>
                                    <option key="0" defaultValue>Tất cả hoạt động</option>
                                    <option key="1">Nạp tiền</option>
                                    <option key="2">Rút tiền</option>
                                    <option key="3">Mua vé</option>
                                </Input>
                                <Table bordered id="table-customer-History">
                                    <thead>
                                        <tr>
                                            <th className="content-title-bold">Hoạt động</th>
                                            <th className="content-title-bold">Số tiền</th>
                                            <th className="content-title-bold">Số tiền trước khi giao dịch</th>
                                            <th className="content-title-bold">Số tiền sau khi giao dịch</th>
                                            <th className="content-title-bold">Ngày nạp/rút tiền</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.historyCustomer.map((row,key)=>{
                                                return(
                                                    <tr>
                                                        <td>{actionRechargeOnDay.find(action => action.id == row.fluctuationTypeId).label}</td>
                                                        <td>{(row.value*1000).getMoneyFormat() + " đ"}</td>
                                                        <td>{(row.beforeTransaction*1000).getMoneyFormat() + " đ"}</td>
                                                        <td>
                                                        {(row.fluctuationTypeId=== "1" || row.fluctuationTypeId === "2"|| row.fluctuationTypeId === "9"|| row.fluctuationTypeId === "10") 
                                                            ? (((row.beforeTransaction-row.value)*1000).getMoneyFormat() + " đ") 
                                                            : (((row.beforeTransaction+row.value)*1000).getMoneyFormat() + " đ") 
                                                            }
                                                        </td>
                                                        <td>{row.createdDate}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <Button className="float-left ml-2" disabled={this.state.historyPage===1} onClick={this.handPrePageHistory.bind(this)}>Trước</Button>
                                <Button className="float-right mr-2" onClick={this.handNextPageHistory.bind(this)}>Sau</Button>
                            </Row>
                            </center>
                        </Col>
                    </Row>
                    
                </Col>
            </React.Fragment>
        )
    }
}

export default ReCharge;